import { gql } from '@apollo/client'

export const GET_COMPANY = gql`
  query Company($companyId: ID!) {
    company(companyId: $companyId) {
      id
      isCompanyCustomer
      isDemo
      isInDevelopmentMode
      name
      country
      organizationNumber
      superAdminNote
      fleetEnabled
      cannotBeDeletedReason
      logo(settings: { width: 200 }) {
        id
        url
        src
        width
        height
      }
      scriptUser {
        id
      }
      projects {
        id
        name
        builderProjectIds
        projectCode
        primaryCompany {
          name
        }
        userConnectionsCount
        start
        stop
        isDemo
        isArchived

        # currentCount: itemsCount(filter: all)
        # doneCount: itemsCount(filter: may_return)
        # dueCount: itemsCount(filter: due)
      }
      depots {
        id
        name
        isDemo
        userConnections {
          user {
            id
          }
        }
      }
    }
  }
`

export const GET_COMPANY_USERS = gql`
  query CompanyUsers($companyId: ID!) {
    company(companyId: $companyId) {
      id
      companyUsers(showRemoved: true) {
        administrator
        id
        isRemoved
        user {
          id
          name
          phoneNumbers
          gdprConfirmed
          email
          isSuperAdmin
          archived
          hasEnabledTrackingDeviceTokens
          projectConnections {
            project {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_COMPANY_PROJECT_USERS = gql`
  query CompanyProjectUsers($companyId: ID!) {
    company(companyId: $companyId) {
      id
      users {
        id
        firstName
        lastName
        phoneNumbers
        gdprConfirmed
        email
        isSuperAdmin
        archived
        projectConnections {
          project {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_COMPANY_USER_COUNTS = gql`
  query CompanyUserCounts($companyId: ID!) {
    company(companyId: $companyId) {
      id
      users {
        id
      }
      companyUsers {
        user {
          id
        }
      }
    }
  }
`
